<template>
  <div>
    <h1 class="mb-2">Gestão de redescontos</h1>
    <b-row>
      <b-col>
        <datatable
          ref="redescontosDatatable"
          @row-action="rowAction"
          :customFields="customFields"
          :customActions="customActions"
          :showEditPage="false"
          :showRediscountAtions="showRediscountAtions()"
        >
          <template #table-header-actions>
            <div class="d-flex justify-content-start align-items-center">
              <b-input-group class="input-group-merge mr-2">
                <b-form-input v-model="searchByNumber" class="form-control-merge" placeholder="Buscar por numero" />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" size="18" />
                </b-input-group-append>
              </b-input-group>
              <v-select
                v-model="selectedAssignor"
                :options="assignors"
                class="assignor-selector d-flex flex-column mr-2"
                label="nome"
                placeholder="Cedente"
              >
              </v-select>
              <v-select
                v-model="selectedStatus"
                :options="statuses"
                class="assignor-selector d-flex flex-column mr-2"
                placeholder="Status"
              >
              </v-select>
              <flat-pickr
                v-model="selectedDate"
                class="assignor-selector form-control bg-white"
                :config="config"
                placeholder="Busca por data"
              />
            </div>
          </template>
        </datatable>
      </b-col>
    </b-row>
    <reject-modal :selectedItem="selectedItem" />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BModal,
  BTable,
  BBadge,
  BButton,
  BTooltip,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import RejectModal from '@/views/admin/rediscount/components/RejectModal'
import {
  linkToRemessa,
  statusRedescontoFormatter,
  brazilianFormattedDate,
} from '@core/comp-functions/data_visualization/datatable'
import downloadFile from '@/utils/download-file'
import http from '/src/services/http'

export default {
  name: 'Concessoes',
  components: {
    BCol,
    BRow,
    BCard,
    BTable,
    BModal,
    BBadge,
    BButton,
    vSelect,
    BTooltip,
    flatPickr,
    Datatable,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    RejectModal,
  },
  data() {
    return {
      selectedItem: {},
      searchByNumber: '',
      selectedDate: '',
      selectedStatus: null,
      selectedAssignor: null,
      assignors: [],
      statuses: [
        { value: 'pendente', label: 'Pendente' },
        { value: 'processando', label: 'Processando' },
        { value: 'executado', label: 'Executado' },
        { value: 'rejeitado', label: 'Rejeitado' }
      ],
      config: {
        mode: 'range',
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      customFields: [
        {
          label: 'ID',
          key: 'id_cashu',
        },
        {
          label: 'Cedente',
          key: 'cedente_razao_social',
          sortable: true,
        },
        {
          label: 'Remessa',
          key: 'remessa',
          sortable: true
        },
        {
          label: 'Valor de Baixa',
          key: 'valor_baixa',

        },


        {
          label: 'Data de Baixa',
          key: 'data_baixa',
          formatter: brazilianFormattedDate,

        },

        {
          label: 'Data de solicitação',
          key: 'created_at',
          sortable: true,
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
          formatter: statusRedescontoFormatter,
        },
        {
          label: '',
          key: 'actionsRediscount',
        },
      ],
      customActions: [
        {
          label: 'Visualizar',
          icon: 'icon-search',
          action: 'show',
          name: 'redescontos.show',
        },
      ],
    }
  },
  async mounted() {
    await this.getAssignors()
  },
  methods: {
    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data
    },
    rowAction({ action, payload }) {
      const actions = {
        marcarProcessado: 'marcarProcessado',
        marcarExecutado: 'marcarExecutado',
        marcarRejeitado: 'marcarRejeitado',
        BaixarCnab: 'BaixarCnab',
      }
      return this[actions[action]](payload)
    },
    async marcarProcessado(payload) {
      await this.$store.dispatch('admin/setProcessando', payload.id)
      this.$refs.redescontosDatatable.getData()
    },
    async marcarExecutado(payload) {
      await this.$store.dispatch('admin/setExecutado', payload.id)
      this.$refs.redescontosDatatable.getData()
    },
    async marcarRejeitado(payload) {
      this.selectedItem = payload
      this.$bvModal.show('rejectModal')
    },
    async BaixarCnab(payload) {
      const { data } = await http.get(`recebiveis/cashu/redesconto/${payload.id}/cnab`, {
        headers: { accept: 'application/json' },
      })

      downloadFile(data, `cnab_${payload.id}.txt`, 'text')
    },
    showRediscountAtions() {
      return !(this.$route.name === 'partner.redescontos.index')
    }
  },
  computed: {
    params() {
      let params = {}

      if (this.selectedAssignor) params.cedente_id = this.selectedAssignor.id
      if (this.selectedStatus) params.status = this.selectedStatus.value
      if (this.selectedDate.includes(' até ')) {
        let [inicio, fim] = this.selectedDate.split(' até ')
        params.data_inicio = inicio;
        params.data_fim = fim;
      } else {
        params.data_inicio = this.selectedDate
      }
      if (this.searchByNumber) params.numero_remessa = this.searchByNumber

      if (this.datatableFilterValues) params = { ...params, ...this.datatableFilterValues }

      return params
    }
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.redescontosDatatable.updateFilterValues(newValue)
    },
  },
}
</script>

<style>
.v-select {
  min-width: 200px;
}
.remessa-link {
  color: #0000EE;
}
</style>
